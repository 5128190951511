export const MAIN_SCHEMA = {
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  name: 'goalskeeper.io | DIY Marketing Insights for Small Business Owners',
  url: 'https://goalskeeper.io',
  description:
    'Get clear, actionable DIY marketing insights with goalskeeper.io. Connect your tools, track results, and grow your business—no tech skills required!',
  isPartOf: {
    '@type': 'WebSite',
    name: 'goalskeeper.io'
  },
  primaryImageOfPage: {
    '@type': 'ImageObject',
    url: 'https://goalskeeper.io/og.png'
  },
  breadcrumb: {
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: 'https://goalskeeper.io'
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Blog',
        item: 'https://blog.goalskeeper.io'
      }
    ]
  },
  about: {
    '@type': 'Organization',
    name: 'goalskeeper.io',
    url: 'https://goalskeeper.io',
    logo: 'https://goalskeeper.io/icons/icon-144x144.png',
    description: 'Clear, actionable DIY marketing insights for small business owners.',
    sameAs: [
      'https://www.facebook.com/goalskeeper.io.achieving.business.goals/',
      'https://x.com/goalskeeper_io'
    ],
    contactPoint: {
      '@type': 'ContactPoint',
      email: 'mailto:success@goalskeeper.io',
      contactType: 'customer service',
      availableLanguage: ['English']
    },
    hasPart: {
      '@type': 'Blog',
      name: 'The DIY Marketing Hub',
      url: 'https://blog.goalskeeper.io/',
      description:
        'The DIY Marketing Hub is your go-to resource for small business owners and solopreneurs looking to grow smarter.'
    }
  },
  mainEntity: {
    '@type': 'SoftwareApplication',
    name: 'goalskeeper.io',
    operatingSystem: 'Web',
    applicationCategory: 'MarketingSoftware',
    offers: {
      '@type': 'Offer',
      price: '15',
      priceCurrency: 'USD',
      description: 'Free 14-day trial available'
    }
  }
};
